import { Button, useWalletModal, ButtonProps } from '@pancakeswap/uikit'
import useAuth from 'hooks/useAuth'
import styled from 'styled-components'
// @ts-ignore
// eslint-disable-next-line import/extensions
import { useActiveHandle } from 'hooks/useEagerConnect.bmp.ts'
import { useTranslation } from '@pancakeswap/localization'
import Trans from './Trans'

const WalletButton = styled(Button)`
  height: 56px;
  padding: 0 20px;
  background-color: #d4156d;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  transition: all 0.2s linear;
  border-radius: 6px;
  box-shadow: none;

  @media (max-width: 767.98px)  {
    height: 36px;
    padding: 0 11px;
    font-size: 14px;
  }

`

const ConnectWalletButton = ({ children, ...props }: ButtonProps) => {
  const { t } = useTranslation()
  const { login } = useAuth()
  const handleActive = useActiveHandle()
  const { onPresentConnectModal } = useWalletModal(login, t)

  const handleClick = () => {
    if (typeof __NEZHA_BRIDGE__ !== 'undefined') {
      handleActive()
    } else {
      onPresentConnectModal()
    }
  }

  return (
    <WalletButton onClick={handleClick} {...props}>
      {children || <Trans>Connect Wallet</Trans>}
    </WalletButton>
  )
}

export default ConnectWalletButton
