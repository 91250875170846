import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme { }
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family:  'Montserrat', sans-serif;
    color:#E5E9F0;
    font-size:16px;
    line-height: 20px;
    font-weight:400;
  } 
  .text-right {text-align: right}
  .text-top {text-align: top}
  .text-left {text-align: left}
  .text-bottom {text-align: bottom}
  .text-center {text-align: center}
  .bold{font-weight:700}
  .btn-primary {
    background-color:#F58227!important;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    border-radius:30px!important;
    height:40px!important;
    &:hover {
      color:#F58227;
      background-color:#212936!important;
      border:1px solid #F58227;
    }

    &:disabled, &.pancake-button--disabled {
      background-color:#8e8e8e!important;
    }
  }
  
  .btn-secondary {
    background-color:#F58227!important;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    border-radius:30px!important;
    height:40px!important;
    color:#121B24;
    &:hover {
      color:#F58227;
      background-color:#212936!important;
      border:1px solid #F58227;
    }
  }
 
  .reset-input-default {
    box-shadow: none;
    background: transparent;
    border: 0px;
  }
  input.reset-input-default:focus:not(:disabled){
    box-shadow: none;
    outline: none;
  }
  
  .mt-61{
    margin-top:61px;
  }
  footer{
    background:#000000;
    .footer {
      padding:20px;
      .footer-social{
        display: flex;
        align-items: center;
        gap: 30px;    
        .twitter svg path {
          fill: #33CCFF;
        }   
        .tele svg path {
          fill: #34AADF;
        }   
        .medium svg path {
          fill: #fff;
        }   
        .discord svg path {
          fill: #5865F2;
        }   
      }
    }   
    @media screen and (max-width: 575px) {
      .footer {
        margin-bottom:50px;
      }
    }
  }

  
  body {
    background-color: #121b24;   
    img {
      height: auto;
      max-width: 100%;
    }
    .w-full {
      width: 100%;
    }  
    .fs-1 {
      font-size:36px;
      font-weight:700;
      line-height:44px;
      text-transform: uppercase;
      margin-bottom:24px;
    }
    .fs-2 {
      font-size:32px;
      font-weight:700;
      line-height:40px;
      text-transform: uppercase;
    }
    .fs-3 {
      font-size:24px;
      font-weight:700;
      line-height:29px;
      text-transform: uppercase;
    }
    .fs-4 {
      font-size:20px;
      font-weight:700;
      line-height:30px;
      text-transform: uppercase;
    }    
    .fs-5 {
      font-size:16px;
      font-weight:700;
      line-height:20px;
      color:#E5E9F0;
    }

    .o-color {
      color:#F58227;
    }

    .w-color{
      color:#E5E9F0;
    }

    .title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
    /* .card__thumb {
      padding-bottom:6px;
      margin-bottom:17px;
    } */
    .ido-info {
      gap:10px;
      margin-bottom:5px;     
      &__name{
        border:1px solid #F58227;
      padding:1px 14px;
      color:#F58227;
      border-radius:12px;
      font-weight:500;
      font-size:12px;
      } 
    }
   
    .inf-social{
      align-items:center;
      gap:30px;
      padding:15px 0;
      .twitter i {
        color:#33ccff;
        font-size:18px;
      }
      .telegram i,{
        font-size:18px;
        color:#34aadf;        
      }
      .facebook i {
        font-size:18px;
        color:#0066ff;        
      }
      .discord i {
        font-size:18px;
        fill:#5865f2;
      }
      .medium i {
        font-size:18px;
        color:#fff;
      }
      .google i {
        font-size:18px;
        color:#d20000;
      }
      .linkedin i {
        font-size:18px;
        color:#fff;
      }
    }
    .card__price{
      padding-top:20px;
      .card__inf__price{
        line-height:20px;
        font-size:16px;
        color:#F58227;
        font-weight:700;
        padding-left:10px;
      }
    }      

    @media screen and (max-width: 575px) {
      .fs-1 {
        font-size:32px;
        line-height:40px;
      }
      .fs-2 {
        font-size:26px;
        line-height:30px;
      }
      .fs-3 { 
        font-size:22px;
        line-height:30px;
      }
    }
  }



  .container {
    width: 1200px;
    margin: 0 auto;
  
    @media screen and (max-width: 1230px) {
      width: 100%;
    }
  }

  .card {
    a {
      display:block;
    }
    &__thumb {
      background-color: #212936;
      border-radius: 16px;
      height: 235px;
      border: 10px solid #212936;
      overflow: hidden;
      display: flex;
      align-items: center;
      margin-bottom:20px;
      img {
        border-radius:8px;
      }
    }
  }

  .a-hover:hover > div {
    color:#F58227;
    transition: all ease-in-out 0.3s;
  }

  .breadcrumbs {
    display:flex;
    align-items:center;
    gap:5px;
    text-transform:uppercase;
    margin-bottom:44px;
    span.active:hover{
      color:#F58227;
      transition: all ease-in-out 0.3s;
      cursor:pointer;
    }
  }
  .block-line{
    height:1px;
    background:#E5E9F0 ;
    margin-bottom: 15px;
  }

  button.btn-wallet {
    background:transparent;
    font-weight:400;
  }
`

export default GlobalStyle
