import { FooterLinkType } from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  {
    label: t('About us'),
    items: [
      {
        label: t('Roadmap'),
        href: '/',
        isHighlighted: true,
      },
      {
        label: t('Our Team'),
        href: '/',
      },
      {
        label: t('Features'),
        href: '/',
      },
      {
        label: t('Our Backer'),
        href: 'https://docs.pancakeswap.finance/contact-us/telegram',
      },
    ],
  },
  {
    label: t('Contact'),
    items: [
      {
        label: t(' (+84) 99049 84993'),
        href: 'tel:099049 84993',
      },
      {
        label: t('support@speedcouple.com'),
        href: 'mailto:support@speedcouple.com',
      },
      {
        label: t('442 Belle Terre St Floor 7, Francisco'),
      },
    ],
  },
]
