import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  isOpenModalLaunchPad: false,
  itemSelect:{}
}

export const LaunchPadSlice = createSlice({
  name: 'LaunchPad',
  initialState,
  reducers: {
    onShowModalLaunchPad: (state, action) => {
      state.itemSelect = action.payload.data
      state.isOpenModalLaunchPad = action.payload.isOpen
    },

  },

})

// Actions
export const {
  onShowModalLaunchPad,
} = LaunchPadSlice.actions

export default LaunchPadSlice.reducer