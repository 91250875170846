import Cookie from 'js-cookie'
import { createContext, useContext, useState } from 'react'

export const AuthContext = createContext({
  currentUser: null,
  token: "",
  setToken: (val) => void (val),
  setCurrentUser: (val) => void (val)
})

export const AuthProvider = ({ children }) => {
  const [user, _setUser] = useState(Cookie.get('ACCESS_USER_INF') ? JSON.parse(Cookie.get('ACCESS_USER_INF')) : null)
  const [token, _setToken] = useState(Cookie.get('ACCESS_TOKEN'))

  const setCurrentUser = (_user) => {
    _setUser(_user)
    if (_user) {
      Cookie.set('ACCESS_USER_INF', JSON.stringify(_user), { path: '/', secure: true },)
    } else {
      Cookie.remove('ACCESS_USER_INF')
    }
  }

  const setTokenUser = (_token) => {
    _setToken(_token)
    if (_token) {
      Cookie.set('ACCESS_TOKEN', _token, {
        path: '/'
      })
    } else {
      Cookie.remove('ACCESS_TOKEN')
    }
  }

  return (
    <AuthContext.Provider value={{
      currentUser: user,
      setCurrentUser,
      token,
      setToken: setTokenUser
    }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => useContext(AuthContext)