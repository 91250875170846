import { useTranslation } from '@pancakeswap/localization'
import {
  Flex,
  LogoutIcon,
  UserMenuDivider,
  UserMenuItem,
  useModal
} from '@pancakeswap/uikit'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import axios from 'axios'
import Cookie from 'js-cookie'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useAuthContext } from 'contexts/UserContext'
import crypto from 'crypto'
import useAuth from 'hooks/useAuth'
import { useRouter } from 'next/router'
import { memo, useEffect } from 'react'
import { UserMenu as UserProfile } from '../components/UserMenu/index'
import WalletModal, { WalletView } from './WalletModal'
import WalletUserMenuItem from './WalletUserMenuItem'

const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL
const SECRET_KEY = process.env.NEXT_PUBLIC_SECRET

const getUserCookie = Cookie.get('ACCESS_USER_INF')
const parsetUser = getUserCookie && JSON.parse(Cookie.get('ACCESS_USER_INF')) 
const IMG_DOMAIN = process.env.NEXT_PUBLIC_THUMB_URL

const UserMenu = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const { account, error } = useWeb3React()
  const { logout } = useAuth()
  const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
  const [onPresentWrongNetworkModal] = useModal(<WalletModal initialView={WalletView.WRONG_NETWORK} />)
  const isWrongNetwork: boolean = error && error instanceof UnsupportedChainIdError
  const { currentUser, setCurrentUser, setToken } = useAuthContext()

  // console.log("parsetUser", parsetUser)
  useEffect(() => {
    if (account) {
      if (!parsetUser) {
        const signature = crypto.createHmac("sha256", SECRET_KEY).update(`wallet=${account}`).digest('hex')
        const url = `${BASE_URL}users/login_wallet`
        const data = {
          wallet: account,
          signature
        }
        axios.post(url, data).then((res) => {
          if (res.status === 200) {
            setToken(res.data.user.token)
            setCurrentUser(res.data.user)
            window.location.reload()
          }
        })
        return
      }
    }

    if (account && parsetUser && parsetUser.wallet !== account) {
      const signature = crypto.createHmac("sha256", SECRET_KEY).update(`wallet=${account}`).digest('hex')
      const url = `${BASE_URL}users/login_wallet`
      const data = {
        wallet: account,
        signature
      }
      axios.post(url, data).then((res) => {
        if (res.status === 200) {
          setToken(res.data.user.token)
          setCurrentUser(res.data.user)
          window.location.reload()
        }
      })
    }

  }, [account])


  // useEffect(() => {
  //   if (hasPendingTransactions) {
  //     setUserMenuText(t('%num% Pending', { num: pendingNumber }))
  //     setUserMenuVariable('pending')
  //   } else {
  //     setUserMenuText('')
  //     setUserMenuVariable('default')
  //   }
  // }, [hasPendingTransactions, pendingNumber, t])

  const onClickWalletMenu = (): void => {
    if (isWrongNetwork) {
      onPresentWrongNetworkModal()
    } else {
      onPresentWalletModal()
    }
  }




  const UserMenuItems = () => {
    return (
      <>
        <WalletUserMenuItem isWrongNetwork={isWrongNetwork} onPresentWalletModal={onClickWalletMenu} />
        {/* <WalletUserMenuItem isWrongNetwork={true}  onPresentWalletModal={onClickWalletMenu} /> */}
        {/* <UserMenuItem as="button" disabled={isWrongNetwork} onClick={onPresentTransactionModal}>
          {t('Recent Transactions')}
          {hasPendingTransactions && <RefreshIcon spin />}
        </UserMenuItem> */}
        <UserMenuDivider />
        <UserMenuItem
          as="button"
          disabled={isWrongNetwork}
          onClick={() => router.push(`/bag?search=&page=1`)}
        >
          {t('Your NFTs')}
        </UserMenuItem>
        <UserMenuItem
          as="button"
          onClick={() => router.push(`/profile`)}
        >My account</UserMenuItem>
        <UserMenuDivider />
        <UserMenuItem as="button" onClick={logout}>
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            {t('Logout')}
            <LogoutIcon />
          </Flex>
        </UserMenuItem>
      </>
    )
  }

  if (account) {
    return (
      <UserProfile account={account} 
      avatarSrc={currentUser?.avatar_url!== "" ? IMG_DOMAIN + currentUser?.avatar_url : null} >
        {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
      </UserProfile>
    )
  }

  // if (isWrongNetwork) {
  //   return (
  //     <UIKitUserMenu text={t('Network')} variant="danger">
  //       {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
  //     </UIKitUserMenu>
  //   )
  // }



  return (
    <ConnectWalletButton scale="sm" className='btn-wallet'>
      Login
    </ConnectWalletButton>
  )
}

export default memo(UserMenu) 
