import { ContextApi } from '@pancakeswap/localization'
import {
  EarnFillIcon,
  EarnIcon,
  HomeIcon,
  MenuItemsType,
  NftFillIcon, NftIcon
} from '@pancakeswap/uikit'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean } & {
  items?: ConfigMenuDropDownItemsType[]
}

const filterItemBySupportChainId = (item, chainId) => {
  return !chainId || !item.supportChainIds ? true : item.supportChainIds?.includes(chainId)
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
    [
      {
        label: t('Home'),
        icon: HomeIcon,
        fillIcon: HomeIcon,
        href: '/',
        showItemsOnMobile: false,
        items: [],
        path: "/"
      },
      {
        label: t('Launchpad'),
        href: "/launchpad?page=1&search=&type=&cid=",
        icon: NftIcon,
        fillIcon: NftFillIcon,
        showItemsOnMobile: false,
        items: [],
        path: "/launchpad"
      },
      {
        label: t('Staking'),
        href: `/staking?page=1&wallet=`,
        icon: EarnIcon,
        fillIcon: EarnFillIcon,
        showItemsOnMobile: false,
        items: [],
        path: "/staking"
      },
      {
        label: t('Market'),
        href: "/market?page=1&search=&sort=newest",
        icon: NftIcon,
        fillIcon: NftIcon,
        showItemsOnMobile: false,
        path: "/market",
        items:[]
        // items: [
        //   {
        //     label: "Market",
        //     href: '/market?page=1&search=&sort=newest',
        //     path: "/market"
        //   },
        //   {
        //     label: "Purchasing shop",
        //     href: '/purchasing-shop',
        //     path: '/purchasing-shop',
        //   },
        //   {
        //     label: "Blind box",
        //     href: '/blindbox',
        //     path: '/blindbox',
        //   },
        // ]
      },
    ].filter((item) => filterItemBySupportChainId(item, chainId))

export default config
